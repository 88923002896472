import * as React from "react";
import Layout from "../components/layout";
import { Header } from "../components/organisms/Header"
import SEO from "../components/seo"

export type Messages = {
  title: string;
  subTitle: string;
  description: string;
  buttonText: string;
};

type Props = {
  messages: Messages;
};

const ErrorPage: React.FC<Props> = ({messages}) => {
  return (
    <Layout>
	    <SEO title={messages.title} description={messages.subTitle} robotsMetaContent="noindex" />
			<Header 
				title={messages.title} 
				subTitle={messages.subTitle}
				textInDownPosition={true}
				description={messages.description}
				buttonText={messages.buttonText}
				buttonLink="/"
			/>
    </Layout>
  )
};

export default ErrorPage;
